export const NORMS = {
    'age-ranges': [
      [2, 17],
      [18, 19],
      [20, 24],
      [25, 29],
      [30, 34],
      [35, 44],
      [45, 54],
      [55, 64],
      [65, 69],
      [70, 74],
      [75, 79],
      [80, 84],
      [85, 89],
    ],
    means: [34.5, 34, 34, 34, 33.5, 32.5, 30.5, 28, 26, 23.5, 21, 17.5, 15.5],
    sds: [8, 8.5, 8.5, 8.5, 8, 8.3, 7.8, 7.5, 7.3, 7.5, 7.3, 6.8, 6.5],
  };