const symbols = '⋖⩹≮≉⩪<∢⊕⊖⊗±⪤⪽∪⊅⟃⩂⩃⊢⊧⦻⤳⥋⥤⥲⊏⊞⊩∟‡≠⏆∮≷⊁∼≈≬⥾⟧'.split('');
const symmetric = '⊕⊞⊗⦻';
const semiSymmetric = '⊖‡≠⏆⥋∮≬⪤∼≈≉≷';
const asymmetric = symbols.filter(
  (symbol) => !symmetric.includes(symbol) && !semiSymmetric.includes(symbol)
);

const zeroRotations = symmetric
  .split('')
  .map((symbol) => ({ symbol, degrees: 0 }));
  
const twoRotations = semiSymmetric
  .split('')
  .map((symbol) => [
    { symbol, degrees: 0 },
    { symbol, degrees: 90 },
  ])
  .flat(1);

const fourRotations = asymmetric
  .map((symbol) => [
    { symbol, degrees: 0 },
    { symbol, degrees: 90 },
    { symbol, degrees: 180 },
    { symbol, degrees: 270 },
  ])
  .flat(1);

export const SYMBOLS = [...zeroRotations, ...twoRotations, ...fourRotations];