<template>
    <div class="relative w-full h-full">
      <div 
        class="relative flex flex-col justify-center items-center w-full h-full text-xl md:text-2xl xl:text-3xl overflow-hidden"
        v-bind:style="{
            'height': mobile ? result ? '70vh' : '70vh' : '60vh',
            'backgroundColor': bgColor, 
          }"
      >
				<div v-show="!testStarted" class = "relative w-5/6 h-full flex flex-col justify-center items-center">
					The 2 symbols to the left 
					(on gray background) are objectives.<br> 
          Find the symbol on white background that matches
					either of the objectives, and click it.<br> <br> 
          There can be at most 1 match.
          If there is no match, click NO
          <div class="flex justify-center items-center mb-3">
            <div>Age: </div>
            <input
              type="number" 
              id="age" 
              v-model="age"
              @keydown.enter="userClickedStart"
              class="w-1/2 block text-white text-center bg-black border-2 border-white"
            />
          </div>
          <div v-show="ageErrMsg.length > 0" class="mb-3">
            {{  ageErrMsg  }}
          </div>
					<button 
						class="text-lg rounded-lg" 
						style="backgroundColor:lightgreen; width: 90px; height: 30px"
						@click.stop="userClickedStart"
					>
						start
					</button>
				</div>

				<div v-show="testStarted" class = "w-full h-full flex flex-col items-center text-black"> 
          <div v-show="!showingResult" class="w-full justify-start">
            <div class="text-white text-center text-4xl">Timer</div>
            <div v-show="!mobile||!result" class="flex w-full my-5 justify-between items-center" >
              <span class="text-xs mr-2">0%</span>
              <div ref="progressbarWrapper" class="relative flex-grow border-2 border-white" style="height:20px;">
                <div class="h-full progressbar" :style="{'width':`${progressBarWid}px`}"/>
              </div>
              <span class="relative left-2 text-xs">100%</span>
            </div>
            <!-- <div v-show="testStarted" class = "absolute w-1/2 flex flex-col justify-start items-center" style="height:20px; top:8%"> -->
            
          </div>
        
          <div class="relative w-full h-full flex flex-col items-center justify-center">
            <div v-show="!result" class="grid-container">
              <div
                class="square"
                v-for="(symbolObj, idx) in symbols"
                :class="[idx <= 1 ? 'bg-gray-400' : 'bg-white']"
                :key="idx"
                @click="clickItem(symbolObj, idx)"
              >
                <div 
                  class="number"
                  :style="{ transform: `rotate(${symbolObj.degrees}deg)` }"
                >
                  {{ symbolObj.symbol }}
                </div>
              </div>
            </div>
            <div v-show="showingResult" class="text-black">
              <div>{{result}}</div>
              <div 
                class="relative flex justify-end items-center"
              >
                <img 
                  v-if="assetUrl !== undefined"
                  :src="assetUrl"
                  class="cursor-pointer"
                  style="width: 2.5rem; height: 2.5rem"
                  @click="restart()"
                  @error="showDefault(err)"
                    />
                <div class="ml-2"> try again </div>
              </div>
            </div>
					</div>
					<!-- <div class="grid-item"></div> -->
					
					<div id="countdownscene" style="font-size:7rem;">
						<div id="card" ref="card">
							<div class="card_face face_front">{{countDownNum1}}</div>
							<div class="card_face face_back">{{countDownNum2}}</div>
						</div>
					</div>
				</div>
			</div>
      <div v-show="showingResult" class="z-100 mt-5 flex justify-center items-center w-full text-lg">
        <div>Share your symbol search IQ!</div>
        <button 
          id="kakao_share" 
          class="ml-6 cursor-pointer" 
          style="width:50px; height:50px;"
          @click="kakaotalkShare"
        >
          <img src="@/assets/kakaotalk_logo-256px.png"/>
        </button>
      </div>
    </div>
    </template>
    
    <script>
    import { SYMBOLS } from '../utils/WAISSymbols.js';
    import { NORMS } from '../utils/WAISNorm.js';
    // import { SCALED_SCORES } from './scaled-scores.js';
    import { shuffle } from '../utils/array.js';
    export default {
      name: 'WAISIVSymbolSearch',
      components: {
      },
      props: {
        mobile: Boolean,
      },
      computed: {
      },
      methods: {
        cmpSymbolObj(obj1, obj2) {
          return obj1.symbol === obj2.symbol && obj1.degrees === obj2.degrees
        },
        checkAnsweer(symbolObj) {
          return this.cmpSymbolObj(symbolObj, this.symbols[0]) || 
            this.cmpSymbolObj(symbolObj, this.symbols[1])
        },
        clickItem(symbolObj, idx) {
          if(idx == 2) return;
          let correct = true;
          if(idx == 8) {
            for(let i = 3; i < 8; i++) {
              if(this.checkAnsweer(this.symbols[i])) {
                correct = false;
                break;
              }
            }
          } else {
            correct = this.checkAnsweer(symbolObj)
          } 
          // console.log('obj0', symbolObj.symbol)
          // console.log('obj1', this.symbols[0].symbol)
          // console.log('obj2', this.symbols[1].symbol)
          if(correct) this.correctCnt++;
          else this.wrongCnt++;
          // console.log('correct', correct)

          this.createSymbols()
        },
        kakaotalkShare() {
          window.Kakao.Link.sendCustom({
            templateId: 101166,
            templateArgs: {
              'iq': `${this.IQ}`,
            },
          });
        },
        validateAge() {
          return this.age >= 2 && this.age < 200
        },
        userClickedStart() {
          if(!this.validateAge()) {
            this.age = null;
            this.ageErrMsg = "input valid age"
            return;
          }
					this.testStarted = true;
          if(this.showingResult) return;
          if(!this.testRunning) {
            console.log("start test!!!");
            this.bgColor = 'black';
            this.countDownNum1 = 3;
            this.countDownNum2 = 2;
            this.result = undefined;
            this.$refs.card.classList.value = 'flip0to270';
            this.testRunning = true;
            this.animationRunning = true;
          } 
          else if(this.testRunning && this.animationRunning) {
            this.$refs.card.classList.value = '' 
            this.countDownNum1 = '';
            this.countDownNum2 = '';
            this.animationRunning = false;
            this.progressBarWid = 0;
            this.startTest();
          }
        },
        startTest() {
          this.createSymbols();
          this.correctCnt = this.wrongCnt = 0;
          this.progressBar();
          this.progressbarWrapperWid = this.$refs.progressbarWrapper.clientWidth;
        },
        progressBar() {
          const currentTimeStamp = new Date();
          if(!this.timeStamp) this.timeStamp = currentTimeStamp;
          const elapsed = (currentTimeStamp - this.timeStamp) / 1000;
          this.progressBarWid = elapsed / this.timeLimit * this.progressbarWrapperWid;
          if(elapsed >= this.timeLimit) {
            this.timeStamp = undefined;
            this.progressBarWid = 0;
            this.animationId = undefined;
            this.showResult();
          } else this.animationId = window.requestAnimationFrame(this.progressBar);
        },
        calcIQ() {
          const ageRange = NORMS['age-ranges'].find(([from, to]) => {
            return this.age >= from && this.age <= to;
          });
          // console.log('age & ageRange', this.age, ageRange)
          const i = NORMS['age-ranges'].indexOf(ageRange);
          // console.log('i', i)
          const mean = NORMS.means[i];
          const sd = NORMS.sds[i];
          console.log('mean, sd', mean, sd)
          let iq = 100 + (((this.correctCnt - this.wrongCnt)- mean) / sd) * 15;
          iq = Math.round(iq);
          return iq;
        },
        showResult() {
          this.progressBarWid = 0;
          this.timeRecord = (new Date() - this.timeStamp) / 1000;
          if(this.animationId) this.cancelAnimation();
          // this.result = success ? 
          //   `Solved in ${this.timeRecord} s` : 
          //   "Fail to solve";
          this.IQ = this.calcIQ()
          this.result = `Your IQ is ${this.IQ}` 
          this.bgColor = '#1F618D';
          this.showingResult = true;
          this.testRunning = false;
        },
        cancelAnimation() {
          window.cancelAnimationFrame(this.animationId);
          this.animationId = undefined;
        },
        restart() {
          this.numberToClick = 1;
          this.showingResult = false;
          this.timeStamp = undefined;
          this.bgColor = 'black';
          this.correctCnt = this.wrongCnt = 0;
          this.testStarted = false;
          // this.startTest();
        },
        createSymbols() {
          const hasMatch = this.coinFlips[this.CURRENT_STEP++]
          if(this.CURRENT_STEP == 61) this.showResult()
          const pool = shuffle(SYMBOLS)
          let optionNumber = 5;
          let symbols = shuffle(pool.slice(0, optionNumber))
          this.symbols = [{}, {}, {}, {}, {}, {}, {}, {}, {}]
          
          for(let [idx, { symbol, degrees }] of Object.entries(symbols)) {
            this.symbols[+idx + 3] = {
              symbol, 
              degrees
            }
          }
          const sliceIdx = hasMatch ? optionNumber - 1 : optionNumber
          const objectives = pool.slice(sliceIdx, sliceIdx + 2)
          this.symbols[0] = {
            symbol: objectives[0].symbol,
            degrees: objectives[0].degrees,
          }
          this.symbols[1] = {
            symbol: objectives[1].symbol,
            degrees: objectives[1].degrees,
          }
          this.symbols[2] = {
            symbol: "",
            degrees: 0
          }
          this.symbols[8] = {
            symbol: "NO",
            degrees: 0
          }
          // console.log(symbols)
          // console.log(this.symbols)
          // this.symbols[2].symbol = ""
          // this.symbols[8].symbol = "NO"
        }
      },
      mounted() {
        this.assetUrl = require(`@/assets/reload.png`);
        const animatedEl = document.getElementById('card');
        animatedEl.addEventListener('animationiteration', () => {
          console.log('animation iterating!!!!');
          this.countDownNum1 -= 2;
          this.countDownNum2 = 'Start';
          if(this.is0To270) this.$refs.card.classList.value = 'flip270to630';
          else {
            this.$refs.card.classList.value = '' 
            this.countDownNum1 = '';
            this.countDownNum2 = '';
            this.animationRunning = false;
            this.startTest();
          }
          this.is0To270 = !this.is0To270;
          this.coinFlips = shuffle([
            ...new Array(this.MAX_PUZZLE_CNT / 2).fill(true),
            ...new Array(this.MAX_PUZZLE_CNT / 2).fill(false),
          ]);
        })
        
        document.addEventListener('keypress', (e) => {
          console.log(e);
          e = e || window.event;
          if (e.key === 'Enter')
            this.userClickedStart();
        });
    
      },
      data() {
        return {
          timeRecord: null,
          animationId: undefined,
          countDownNum1: '',
          countDownNum2: '',
          bgColor: 'black',
					testStarted: false,
          // bgColor: 'white',
          is0To270: true,
          timeLimit: 120,
          progressbarWrapperWid: undefined,
          testRunning: false,
          showingResult: false,
          // testRunning: true,
          // showingResult: true,
          progressBarWid: 0,
          timeStamp: undefined,
          result: undefined,
          numbers: [],
          numbersToUpdate: [],
          numberToClick: 1,
          assetUrl: undefined,
          symbols: [],
          correctCnt: 0,
          wrongCnt: 0,
          age: 20,
          ageErrMsg: "",
          IQ: 100,
          coinFlips: [],
          MAX_PUZZLE_CNT: 60,
          CURRENT_STEP: 0,
        };
      },
    }
    </script>
    <style scoped>
    .grid-container {
      display: flex; /* 플렉스 박스를 사용하여 요소를 한 줄로 정렬 */
      width: 90vw; /* 전체 그리드 너비 */
      margin: auto;
    }

    .square {
      width: 10vw; /* 각 정사각형의 너비 */
      height: 10vw; /* 각 정사각형의 높이 */
      /* background-color: #f0f0f0; */
      /* border: 1px solid #ddd; */
      box-sizing: border-box; /* 테두리와 패딩을 너비와 높이에 포함 */
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer
    }
    .number {
      position: relative;
      /* top: 50%; */
      font-size: 1.5rem;
      /* transform: translateY(-50%); */
    }
    #countdownscene {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      perspective: 1000px;
      display: flex;
    }
    #card {
      /* backface-visibility: visible; */
      position: relative;
      width: 1px;
      height: 1px;
      transform-style: preserve-3d;
      display: flex;
      justify-content: space-around;
      align-items: center;
    }
    input[type="number"] {
      width: 4em;
      height: 100%;
      /* width: 4rem; 입력 박스의 너비 */
      /* height: 1rem; 입력 박스의 높이 */
      /* font-size: 16px; 입력 박스 내 텍스트의 크기 */
    }
    .flip0to270 {
      animation: flip0to270 2s linear infinite;
      animation-play-state: animationRunning;
    }
    .flip270to630 {
      animation: flip270to630 2s linear infinite;
      animation-play-state: animationRunning;
    }
    .card_face {
      backface-visibility: hidden;
      text-align: center;
      color: lightgreen;
      padding: auto;
      position: absolute;
    }
    .face_back {
      transform: rotateY(180deg);
    }
    .face_front {
      transform: rotateY(0deg); 
    }
    .progressbar {
      background: lightgreen;
    }
    @keyframes flip270to630 {
      from { transform: rotateY(270deg) }
      to { transform: rotateY(630deg);}
    }
    @keyframes flip0to270 {
      from { transform: rotateY(0) }
      to { transform: rotateY(270deg) }
    }
    * {
      user-select: none;
      outline: none;
    }
    </style>
    