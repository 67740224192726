<template>
  <div class="my-2">
    <div 
      class="flex flex-col justify-start items-center bg-white rounded-md pt-5 overflow-x-hidden hover"
      :class="{ 'cursor-pointer': !reparing, 'hoverEffect' : !reparing }"
      :style="calcSize"
      @click="pushToRouter"
    >
    <!--color: blue-100 ?-->
      <img 
        v-if="assetUrl !== undefined"
        :src="assetUrl"
        :style="calcImgSize"
        @error="showDefault(err)"
      />
      <div class="mt-6 px-1 mb-5 w-full h-full flex-wrap text-lg font-semibold sm:text-xl md:text-2xl xl:text-3xl"> 
      <!-- <div class="mt-6 px-1 mb-5 w-full h-full truncate text-lg font-semibold sm:text-2xl">  -->
        {{ title }} 
      </div>
      <!-- <div v-for="item in [1,2,3]"
        :key="'key'+item"
        class="w-full bg-blue-400 ml-20 text-lg mb-2"
        style="textAlign:left;"
      >
        item: {{item}}
      </div> -->
      <!-- <div class="bg-yellow-500 mb-">
        description
      </div> -->
    </div>
  </div>
</template>

<script>
const defaultImage = require("@/assets/maintainance.png") //TODO : change default image to show when error occurs
export default {
  name: 'BigCategoryBox',
  props: {
    titleProp: String,
    fileName: String,
    calcSize: Object,
  },
  watch: {
    // calcSize(newVal, oldVal) {
    //   // console.log(newVal.width);
    //   if(newVal == oldVal) return;
    //   if(newVal.width=='300px') 
    //     this.calcImgSize = { 
    //       width: '128px', 
    //       height: '128px'
    //     }; 
    //   else {
    //     this.calcImgSize = { 
    //       // width: `${newVal.width * 3 / 5}px`,
    //       // height: `${newVal.width * 3 / 5}px`
    //       width: '150px',
    //       height: '150px'
    //     }; 
    //   }
    //   console.log(newVal);
    // }
  },
  computed: {
    convertTitleToComponentName() {
      console.log(this.title.replace(/ /g, ''));
      return this.title.replace(/ /g, '');
    },
  },
  mounted() {
    this.title = this.titleProp;
    try {
      this.assetUrl = require(`@/assets/${this.fileName}`);
      console.log("success filename: " + this.fileName)
    } catch(e) {
      console.log(e);
      this.assetUrl = defaultImage;
      this.reparing =  true;
      this.title = "Preparing..."
      console.log(this.title);
      console.log(e);
    }
  },
  data() {
    return {
      assetUrl: undefined,
      title: "Preparing...",
      reparing: false,
      calcImgSize : { 
        width: '64px', 
        height: '64px'
      }, 
    };
  },
  methods: {
    showDefault(err) {
      console.log(err);
      console.log(this.assetUrl);
      this.assetUrl = defaultImage;
    },
    pushToRouter() {
      if(this.reparing) return;
      this.$router.push({
        name: this.convertTitleToComponentName,
        //params:
      });
    }
  }
}
</script>

<style scoped>

#big-caterogy {
  border: solid 0px;
  border-radius: 5rem;
  padding: 4px 1rem;
  margin-bottom: 1rem;
  background-color: rgb(226, 43, 83);
}
.hover {
  color: black;
}
.hover .hoverEffect :hover {
  color: lightgreen;
}
</style>
